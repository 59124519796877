import { useEffect, useState } from 'react'
import { BigNumber } from 'ethers'
import useSWR from 'swr'
import { getPolygonProvider } from 'web/lib/contract'
import usePoller from './usePoller'
import { CacheKey } from 'web/lib/cache-keys'
import usePolygonBlockTime from './usePolygonBlockTime'

export default function usePolygonBlockNumber(enableBlockSimulation: boolean = true) {
  const library = getPolygonProvider()
  const [simulatedBlockNumber, setSimulatedBlockNumber] = useState<BigNumber>(BigNumber.from(0))

  const { data, error } = useSWR(CacheKey.polygonBlock, async () => {
    if (!library) return null

    const blockNumber = await library.getBlockNumber()

    if (!blockNumber) return null

    return {
      blockNumber: blockNumber,
      lastUpdate: new Date()
    }
  }, {
    refreshInterval: 1000 * 60 * 10, // 10 minutes
  })

  // poll every 2 seconds to simulate polygon blocks
  const secondPerBlock = usePolygonBlockTime()
  const poller = usePoller(1000 * secondPerBlock, enableBlockSimulation)

  useEffect(() => {
    try {
      if (enableBlockSimulation) {
        const elapsedSeconds = ((new Date()).getTime() - data.lastUpdate.getTime()) / 1000
        setSimulatedBlockNumber(BigNumber.from(Math.round(data.blockNumber + elapsedSeconds / secondPerBlock)))
      }
      else {
        setSimulatedBlockNumber(BigNumber.from(data?.blockNumber))
      }
    } catch (e) {
      // wrapped in try catch as easy way of ignoring initial setup and weird BigNumber issues
    }
  }, [data?.blockNumber, poller, enableBlockSimulation])


  return simulatedBlockNumber
}
