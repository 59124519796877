import useSWR from "swr";
import { GawdsContractFactory } from "web/lib/contract";
import { TamiGAWDtchi } from "web/types/ethers-contracts";

export default function usePolygonBlockTime(): number {
  const gawchiContract = GawdsContractFactory.get("TamiGAWDtchi") as TamiGAWDtchi

  const { data } = useSWR(`/secondsPerBlock`, async () => {
    return await gawchiContract.secondsPerBlock()
  }, {
    refreshInterval: 0 // cache forever
  })

  return data || 2
}