import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { CacheKey } from 'web/lib/cache-keys'
import { Boon, BoonCollection, BoonChainData } from 'web/lib/boon'
import usePolygonBlockNumber from './usePolygonBlockNumber'
import { GawdsContractFactory } from 'web/lib/contract'
import { BloodShop } from 'web/types/ethers-contracts'

const useMyBoons = (): BoonCollection => {
  const { account, active } = useWeb3React()
  const bloodShopContract = GawdsContractFactory.get("BloodShop") as BloodShop

  const blockNumber = usePolygonBlockNumber(false)
  const [boons, setBoons] = useState<BoonCollection>(
    new BoonCollection([], BigNumber.from(0)) // empty default
  )

  const { data } = useSWR(CacheKey.myBoons(account), async (): Promise<BoonChainData[]> => {
    if (!active) return null

    const allBoonIds = Boon.all.map((b) => b.id)

    // for convenience, combine our inventory of inactive boons w/ our inventory of active boons into one combined list
    const result = await bloodShopContract.boonStats(account, allBoonIds)
    const [inactiveBoonCounts, activationBlocks, expiryBlocks] = result;
    const activeBoonCounts = activationBlocks.map((i) => i.gt(0) ? 1 : 0);
    const boonCounts = inactiveBoonCounts.map((num, i) => num.toNumber() + activeBoonCounts[i]);
    // console.log('useMyBoons:', { inactiveBoonCounts: inactiveBoonCounts.map((n) => n.toNumber()), activeBoonCounts, boonCounts })

    let boonData: BoonChainData[] = []

    for (let i = 0; i < allBoonIds.length; i++) {
      boonData.push({
        id: allBoonIds[i],
        totalOwned: boonCounts[i],
        activatedAtBlock: activationBlocks[i],
        expiresAtBlock: expiryBlocks[i],
      })
    }

    return boonData
  }, {
    refreshInterval: 1000 * 60 * 30, // 30 minutes
  })

  useEffect(() => {
    setBoons(BoonCollection.init(data, blockNumber))
  }, [data, blockNumber])

  return boons
}

export default useMyBoons;