import { BigNumber } from "ethers"

export enum BoonType {
  NFT = 'NFT',
  Keepalive = 'Keepalive',
}

export type BoonChainData = {
  id: number
  totalOwned: number
  activatedAtBlock: BigNumber
  expiresAtBlock: BigNumber
}

export class Boon {
  public uid: number // unique id (since you can own multiple of same boon, use this to differentiate between dupes)
  static uidCounter: number = 0

  public id: number // id of this type of boon
  public type: BoonType
  public name: string
  public shortDescription: string
  public description: string
  public price: number
  public image: string // ERC-721
  public imageUrl: string
  public availableForSale: boolean
  public activatable: boolean

  public isOwned: boolean
  public activatedAt: Date
  public expiresAt: Date
  public activatedAtBlock: BigNumber
  public expiresAtBlock: BigNumber
  public currentBlock: BigNumber

  constructor(data: any) {
    this.id = data?.id
    this.type = data?.type
    this.name = data?.name.toString()
    this.description = data?.description
    this.shortDescription = data?.shortDescription
    this.image = data?.image
    this.price = data?.price
    this.imageUrl = `${process.env.BASE_URL}/images/boons/${data?.image}`
    this.availableForSale = data?.availableForSale || false
    this.activatable = data?.activatable || false

    // TODO replace me with some sort of ownership token id from the chain?
    // note to gpu -- there is no ownership token ID for each item -- you just have X of item 1, Y of item 2, etc. but "3rd item 1" does not have an id
    Boon.uidCounter += 1
    this.uid = Boon.uidCounter
  }

  public get isActivated(): boolean {
    if (!this.activatedAtBlock || !this.expiresAtBlock || !this.currentBlock) return false
    if (this.activatedAtBlock.eq(0) || this.expiresAtBlock.eq(0) || this.currentBlock.eq(0)) return false
    return this.currentBlock.gte(this.activatedAtBlock) && this.currentBlock.lte(this.expiresAtBlock)
  }

  public get isExpired(): boolean {
    if (!this.currentBlock || !this.expiresAtBlock || this.currentBlock.eq(0) || this.expiresAtBlock.eq(0)) return false;
    return this.currentBlock.gt(this.expiresAtBlock);
  }

  public get totalBlockDuration(): BigNumber {
    if (!this.expiresAtBlock || !this.activatedAtBlock) return BigNumber.from(0)
    const diff = this.expiresAtBlock.sub(this.activatedAtBlock)
    return diff
  }

  public getBlocksRemaining(curBlock: BigNumber): BigNumber {
    if (!this.expiresAtBlock || !this.activatedAtBlock) return BigNumber.from(0)

    // if no block passed in, use the block instantiated with the class
    if (!curBlock) {
      return this.expiresAtBlock.sub(this.currentBlock)
    }

    return this.expiresAtBlock.sub(curBlock)
  }

  public getTimeRemaining(curBlock: BigNumber, polygonBlockTime: number): number {
    return this.getBlocksRemaining(curBlock).mul(polygonBlockTime).toNumber()
  }

  static getById(id: number): Boon {
    return this.all.find(c => c.id == id)
  }

  public async activate(_activatedAtBlock: BigNumber, _expiresAtBlock: BigNumber): Promise<void> {
    this.activatedAtBlock = _activatedAtBlock
    this.expiresAtBlock = _expiresAtBlock
  }

  public clone(): Boon {
    return new Boon({ ... this })
  }

  static all: Boon[] = [
    new Boon({
      id: 1,
      name: 'Mainnet Gawd',
      type: 'NFT',
      shortDescription: 'Salvaged from the void',
      description: 'One (1) holographic Gawd ERC-721 non-fungible token, delivered to your address on Ethereum mainnet',
      price: 1000,
      image: 'mainnet-gawd.png',
      attributes: [],
    }),
    new Boon({
      id: 2,
      name: 'Petty Boon',
      type: 'Keepalive',
      shortDescription: '7 days of sated Gawds',
      description: 'Keep your Gawds at peace with this petty crystal shard. Your Gawds will remain at a minimum of 50% health for one week.',
      price: 50,
      image: 'crystal1.svg',
      availableForSale: true,
      activatable: true,
    }),
    new Boon({
      id: 3,
      name: 'Middling Boon',
      type: 'Keepalive',
      shortDescription: '14 days of sated Gawds',
      description: 'Keep your Gawds at peace with this middling crystal shard. Your Gawds will remain at a minimum of 50% health for two weeks.',
      price: 100,
      image: 'crystal2.svg',
      availableForSale: true,
      activatable: true,
    }),
    new Boon({
      id: 4,
      name: 'Ominous Boon',
      type: 'Keepalive',
      shortDescription: '28 days of sated Gawds',
      description: 'Keep your Gawds at peace with this middling crystal shard. Your Gawds will remain at a minimum of 50% health for three weeks.',
      price: 200,
      image: 'crystal3.svg',
      availableForSale: true,
      activatable: true,
    }),
    new Boon({
      id: 5,
      name: 'Divine Boon',
      type: 'Keepalive',
      shortDescription: 'Permanently sated Gawds',
      description: 'Never worry about caring for your Gawds again. Your Gawds will remain at a minimum of 50% health for all time',
      price: 2000,
      image: 'crystal4.svg',
      availableForSale: true,
      activatable: true,
    }),
    new Boon({
      id: 6,
      name: 'Gold Coin',
      type: 'NFT',
      shortDescription: "Something to remember me by",
      description: "Yes we have for you a great product, a wonderful product",
      price: 5,
      image: 'gold-coin.png',
      availableForSale: true,
      attributes: [],
    }),
  ]
}

export class BoonCollection {
  public boons: Boon[]
  public blockNumber: BigNumber

  constructor(boons: Boon[], blockNumber: BigNumber) {
    this.boons = boons
    this.blockNumber = blockNumber
  }

  public static init(chainBoons: BoonChainData[], blockNumber: BigNumber): BoonCollection {
    Boon.uidCounter = 0

    if (!chainBoons) {
      return new BoonCollection([], blockNumber)
    }

    let boons: Boon[] = []

    chainBoons.forEach((chainBoon) => {
      if (!chainBoon) return
      for (let j = 0; j < chainBoon.totalOwned; j++) {
        const boon = Boon.getById(chainBoon.id)
        const newBoon = boon.clone()
        newBoon.isOwned = true
        newBoon.currentBlock = blockNumber

        // currently impossible for boons to stack, so only set activated/expires for the first one (if applicable)
        if (j == 0) {
          newBoon.activatedAtBlock = chainBoon.activatedAtBlock
          newBoon.expiresAtBlock = chainBoon.expiresAtBlock
        }

        boons.push(newBoon)
      }
    })

    return new BoonCollection(boons, blockNumber)
  }

  public findBoon(boon: Boon): Boon {
    return this.all.find(b => b.uid == boon.uid && b.id == boon.id)
  }

  public findBoons(boon: Boon): Boon[] {
    return this.all.filter(b => b.id == boon.id)
  }

  public get all(): Boon[] {
    return this.boons
  }

  public get active(): Boon[] {
    return this.boons.filter(b => b.isActivated)
  }

  public get inactive(): Boon[] {
    return this.boons.filter(b => !b.isActivated)
  }

  public kindOf(type: BoonType): Boon[] {
    return this.boons.filter(b => b.type == type)
  }

  public get keepalives(): Boon[] {
    return this.kindOf(BoonType.Keepalive)
  }

  public get nfts(): Boon[] {
    return this.kindOf(BoonType.Keepalive)
  }
}