import { useEffect, useRef, useState } from 'react'

export default function usePoller(interval = 10000, enabled = true) {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const [pollStarted, setPollState] = useState(false)
  const [poll, setPoll] = useState(0)

  const poller = (inc) => {
    if (!enabled) return

    setTimeout(() => {
      if (mounted.current ) {
        setPoll(inc + 1)
        poller(inc + 1)
      }
    }, interval)
  }

  if (!pollStarted) {
    setPollState(true)
    poller(poll) // starting the polling
  }

  return poll
}